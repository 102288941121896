<template>
    <div class="section">
        <video-overview key="'overview'" :data="overview">
            <template slot="button">
                <router-link class="button overview__btn-play overview__btn-light" :to="{name:route.WATCH_MAIN}">
                    <span class="btn-bg"></span>
                    <span v-if="labels && labels.home">{{ labels.home.button }}</span>
                    <span v-else>{{ text.home.button }}</span>
                </router-link>
            </template>
        </video-overview>
    </div>
</template>

<script>
import store from "../store";
import VideoOverview from "../components/videoOverview";
import routeMixin from "../mixins/routeMixin";
import text from "../text.json"

export default {
    name: "OverviewInfo",
    components: {VideoOverview},
    mixins: [routeMixin],
    props: {active: {type: String}},
    computed: {
        overview() {
            // return store.overview
            return store.preview
        },
        labels(){
            return store.labels
        },
        text() {
            return text
        }
    }
}
</script>

<style scoped>
</style>
