<template>

    <borish-contact :info="item||{}" :shown="shown"></borish-contact>

</template>

<script>
import store from "../store";
import BorishContact from "../components/borishContact";

export default {
    name: "ContactDetails",
    components: {BorishContact},
    props: {action: String, shown: Boolean},
    computed: {
        item() {
            return store.contact[this.action]
        }
    },
    methods: {}
}
</script>

<style scoped>
</style>