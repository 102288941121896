<template>
    <div class="overview">
        <div class="overview__content">
            <h1 v-if="titleMobile" style="white-space: pre-line;" class="overview__title">{{ titleMobile }}</h1>
            <template v-else>
                <h2 class="overview__heading" v-if="data.brief" v-html="data.brief"></h2>
                <h1 class="overview__title" v-if="data.title">{{ data.title }}</h1>
            </template>
            <div v-if="hasButtonSlot" class="overview__buttons">
                <slot name="button"></slot>
            </div>
        </div>
    </div>
</template>

<script>
    // import FigureAction from "./figureAction";
    // import AnimText from "./animText"

    export default {
        // components: {FigureAction, AnimText},
        props: {
            data: Object
        },
        data() {
            return {
                index: 1
            }
        },
        computed: {
            hasButtonSlot() {
                return !!this.$slots.button
            },
            titleMobile() {
                if (!this.$device.mobile)
                    return ''

                return this.data.title_mobile
            }
        },
        methods: {}
    }
</script>
